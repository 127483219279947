<template>
  <div class="row m-0">
    <div class="container mt-5">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <h5 class=" mt-1 mb-0">
                SLA Configuration
              </h5>
              <button
                class="btn btn-sm btn-primary"
                @click="addNewClickHandler"
              >
                Add new
              </button>
            </div>
            <div
              class="card-body p-0"
              style="height: 50em"
            >
              <ag-grid-vue
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                :rowData="rowData"
                class="ag-theme-alpine w-100 h-100 slaEditModal"
              />
            </div>
          </div>
          <div class="d-flex justify-content-center mt-5">
            <div class="card w-50">
              <div class="card-header d-flex justify-content-between">
                <h5 class="mt-1 mb-0">
                  SLA alert recipients
                </h5>
                <button
                  class="btn btn-sm btn-primary"
                  @click="addNewRecipientClickHandler"
                >
                  Add new
                </button>
              </div>
              <div
                class="card-body p-0"
                style="height: 25em"
              >
                <ag-grid-vue
                  :columnDefs="columnRecipientsDefs"
                  :defaultColDef="defaultRecipientsColDef"
                  :rowData="recipientsRowData"
                  class="ag-theme-alpine w-100 h-100 slaEditModal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SlaEditModal
        ref="slaEditModal"
        :viewType="modalViewType"
      />
      <SlaAlertRecipientEditModal
        ref="slaEditRecipientModal"
        :viewType="modalRecipientViewType"
      />
    </div>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue3';
import { mapActions, mapGetters } from 'vuex';
import SlaEditModal from './SlaEditModal';
import { modalViewTypes } from '@/components/constants';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import { slaGreedColumns } from './constants';
import SlaAlertRecipientEditModal from '@/components/SlaConfiguration/SlaAlertRecipientEditModal.vue';
import { booleanIconCellRenderer } from '@/lib/agGridCellRenderers';

export default {
  components: {
    SlaAlertRecipientEditModal,
    AgGridVue,
    SlaEditModal,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer
  },
  data() {
    return {
      modalViewType: modalViewTypes.edit,
      modalRecipientViewType: modalViewTypes.edit
    };
  },
  computed: {
    ...mapGetters({
      rowData: 'sla/slaConfigItems',
      recipientsRowData: 'sla/slaAlertRecipients'
    }),
    defaultColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnDefs() {
      return slaGreedColumns({
        rowClickHandler: this.rowClickHandler
      });
    },
    defaultRecipientsColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnRecipientsDefs() {
      return [
        {
          headerName: '',
          field: 'id',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            clicked: (field) => {
              this.rowRecipientClickHandler(field);
            }
          },
          width: 80
        },
        {
          headerName: 'Email',
          field: 'email',
          width: 300
        },
        {
          headerName: 'Slack Id',
          field: 'slackChannel',
          width: 220
        },
        {
          headerName: 'Receive Low SLA alert',
          field: 'receiveLowSlaAlert',
          cellRenderer: booleanIconCellRenderer(),
          width: 130
        }
      ];
    }
  },
  async mounted() {
    await this.fetchSlaConfigItems();
    await this.fetchSlaAlertRecipients();
  },
  methods: {
    ...mapActions({
      fetchSlaConfigItems: 'sla/fetchSlaConfigItems',
      fetchSlaAlertRecipients: 'sla/fetchRecipients'
    }),
    async rowClickHandler(itemId) {
      this.modalViewType = modalViewTypes.edit;
      await this.$refs.slaEditModal.initModal(itemId);
    },
    addNewClickHandler() {
      this.modalViewType = modalViewTypes.add;
      this.$refs.slaEditModal.initModal();
    },
    async rowRecipientClickHandler(itemId) {
      this.modalRecipientViewType = modalViewTypes.edit;
      await this.$refs.slaEditRecipientModal.initModal(itemId);
    },
    addNewRecipientClickHandler() {
      this.modalRecipientViewType = modalViewTypes.add;
      this.$refs.slaEditRecipientModal.initModal();
    },
  }
};
</script>
