<template>
  <div
    id="sla-recipient-modal"
    ref="slaRecipientModal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ modalTitle }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form v-if="initialized">
            <TextInput
              v-model="item.email"
              :errors="v$.item.email.$errors"
              description="Email"
              label="Email"
              :readonly="!!item.id"
              @blur="v$.item.email.$touch"
            />
            <TextInput
              v-model="item.slackChannel"
              :errors="v$.item.slackChannel.$errors"
              description="Slack ID"
              label="Slack"
              :readonly="!!item.id"
              @blur="v$.item.slackChannel.$touch"
            />
            <CheckboxInput
              v-model="item.receiveLowSlaAlert"
              description="You can disable alert about low SLA for this recipient."
              label="Receive low SLA alerts"
            />
          </form>
          <Spinner v-else />
          <div class="modal-footer d-flex justify-content-between">
            <button
              v-if="isDeleteButtonVisible"
              :disabled="isDeleteButtonDisabled"
              class="btn btn-danger"
              @click="showDeleteConfirmationModalHandler"
            >
              Delete
            </button>
            <button
              v-if="isSaveButtonVisible"
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showSaveConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="sla-alert-recipient-confirmation-modal"
    :onCancelHandler="cancelEditHandler"
    :onConfirmHandler="saveChangesHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The list of recipients will be changed. Are you sure?
        </h6>
      </div>
    </template>
  </ConfirmationModal>
  <DeleteConfirmationModal
    ref="sla-alert-recipient-delete-confirmation-modal"
    :onCancelHandler="cancelDeleteHandler"
    :onConfirmHandler="deleteRecordHandler"
  >
    <template #alert-message>
      Do you really want to delete this recipient?
      This operation can not be undone!
    </template>
  </DeleteConfirmationModal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { email } from '@vuelidate/validators';
import { Modal } from 'bootstrap';
import TextInput from '@/components/common/TextInput';
import Spinner from '@/components/common/Spinner';
import { modalViewTypes, userPrivileges } from '@/components/constants';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import DeleteConfirmationModal from '@/components/common/DeleteConfirmationModal';
import CheckboxInput from '@/components/common/CheckboxInput';
import { hasPrivilege } from '@/service/userAccess';

const defaultItem = {
  id: null,
  email: null,
  slackChannel: null,
  receiveLowSlaAlert: false
};

export default {
  components: {
    ConfirmationModal,
    DeleteConfirmationModal,
    TextInput,
    Spinner,
    CheckboxInput
  },
  props: ['viewType'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      confirmationModalDelete: null,
      confirmationModalSave: null,
      slaAlertRecipientModal: null,
      initialized: false,
      item: {
        id: null,
        email: null,
        slackChannel: null,
        receiveLowSlaAlert: false
      }
    };
  },
  validations() {
    return {
      item: {
        email: {
          email
        },
        slackChannel: {}
      },
    };
  },
  computed: {
    ...mapGetters({
      recipients: 'sla/slaAlertRecipients'
    }),
    modalTitle() {
      return this.viewType === modalViewTypes.edit ? 'Edit row' : 'Add New';
    },
    isSaveButtonDisabled() {
      return !this.initialized;
    },
    isSaveButtonVisible() {
      return hasPrivilege(userPrivileges.createSlaAlertRecipient);
    },
    isDeleteButtonDisabled() {
      return !this.initialized || this.viewType === modalViewTypes.add;
    },
    isDeleteButtonVisible() {
      return hasPrivilege(userPrivileges.deleteSlaAlertRecipient);
    },
    isDuplicateEmail() {
      return this.recipients.some((obj) => {
        if (obj.email) {
          return obj.email === this.item.email;
        }
        return false;
      });
    },
    isDuplicateSlackId() {
      return this.recipients.some((obj) => {
        if (obj.slackChannel) {
          return obj.slackChannel === this.item.slackChannel;
        }
      });
    }
  },
  async mounted() {
    this.slaAlertRecipientModal = new Modal(this.$refs['slaRecipientModal']);
    this.confirmationModalSave = this.$refs['sla-alert-recipient-confirmation-modal'].confirmationModal;
    this.confirmationModalDelete = this.$refs['sla-alert-recipient-delete-confirmation-modal'].confirmationModal;
    this.confirmationModalSave.hide();
    this.confirmationModalDelete.hide();
  },
  methods: {
    ...mapActions({
      fetchRecipientById: 'sla/fetchRecipientById',
      fetchRecipients: 'sla/fetchRecipients',
      deleteRecipientItem: 'sla/deleteRecipient',
      addNewRecipient: 'sla/saveRecipient'
    }),
    async initModal(itemId) {
      this.initialized = false;
      this.slaAlertRecipientModal.show();
      const itemToEdit = itemId ? await this.fetchRecipientById(itemId) : { ...defaultItem };
      this.updateItemData(itemToEdit);
      this.initialized = true;
    },
    updateItemData(newValue) {
      this.item.id = newValue.id;
      this.item.email = newValue.email;
      this.item.slackChannel = newValue.slackChannel;
      this.item.receiveLowSlaAlert = newValue.receiveLowSlaAlert;
    },
    async showSaveConfirmationModalHandler() {
      const errorMessage = await this.validateForm();
      if (errorMessage) {
        this.$toast.error(errorMessage);
        return false;
      }
      this.slaAlertRecipientModal.hide();
      this.confirmationModalSave.show();
    },
    async validateForm() {
      if (!(await this.v$.$validate())) {
        return 'Form should be valid.';
      }
      if (this.viewType === modalViewTypes.add) {
        if (this.isDuplicateEmail) {
          return 'This email already exists in the recipient list.';
        }
        if (this.isDuplicateSlackId) {
          return 'This slack already exists in the recipient list.';
        }
        return null;
      }
    },
    async saveChangesHandler() {
      const dataToSave = {
        ...this.item
      };
      try {
        await this.addNewRecipient(dataToSave);
        const message = this.item.id ? 'Recipient successfully updated' : 'Recipient successfully added';
        this.$toast.success(message);
        await this.fetchRecipients();
      } catch (e) {
        this.$toast.error('Failed to save the recipient data.<br/>'+ e);
      }
    },
    cancelEditHandler() {
      this.confirmationModalSave.hide();
    },
    showDeleteConfirmationModalHandler() {
      this.slaAlertRecipientModal.hide();
      this.confirmationModalDelete.show();
    },
    cancelDeleteHandler() {
      this.confirmationModalDelete.hide();
      this.editModal.show();
    },
    async deleteRecordHandler() {
      try {
        await this.deleteRecipientItem(this.item.id);
        await this.fetchRecipients();
        this.$toast.success('Recipient successfully deleted');
      } catch (e) {
        this.$toast.error('Failed to delete item.');
      }
    }
  }
};
</script>
<style scoped></style>
